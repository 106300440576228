.screen-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;   
    text-align: center;

}

.site-link {
    color: white;
}

.screen {
    padding-top: 10px;
    border: solid transparent;
    border-width: 1px;
    flex: 0 1 auto;
    display: flex;
    align-items: center;
}

.dock-position {
    position: absolute;
    bottom: 25px;
    width: 100%;
}

.iphone-x {
    position: relative;
    margin: 40px auto;
    width: 40vh;
    height: 85vh;
    background-color: #7371ee;
    background-image: url("./assets/background.png");/*linear-gradient(60deg, #7371ee 1%,#a1d9d6 100%);*/
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
}

.iphone-x::before,
.iphone-x::after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.iphone-x::after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #f2f2f2;
    border-radius: 10px;
}

.iphone-x::before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
}
/*
.speaker, .camera {
    position: absolute;
    display: block;
    color: transparent;
}

.speaker {
    top: -15px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}
    
.camera {
    left: 10%;
    top: -15px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}
      
.camera::after {
    content: '';
    position: absolute;
    background-color: #2d4d76;
    width: 6px;
    height: 6px;
    top: 2px;
    left: 2px;
    top: 3px;
    left: 3px;
    display: block;
    border-radius: 4px;
    box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}
*/
